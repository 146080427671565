import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import originStoryImg from '../assets/images/azitek-origin-story.jpg';
import tuggerTrainSmallImg from '../assets/images/tugger-small.png';
import valente from '../assets/images/valente.jpg';

import layoutStyle from '../styles/layout';
import { media, colors } from '../styles/constants';

const BackgroundContainer = styled.div`
  background-color: ${(props) => props.backgroundColor};
  margin-top: 3.75rem;

  > div {
    display: flex;
    flex-direction: column;

    ${layoutStyle}

    h2 {
      text-align: center;
      font-size: 1.8rem;
      padding-bottom: 2rem;

      @media (min-width: ${media.phoneLandscape}px) {
        font-size: 2.5rem;
        align-self: center;
      }

      @media (min-width: ${media.newsTextWidth}px) {
        width: 950px;
      }
    }
  }
`;

const HowItWorksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
`;

const Signature = styled.div`
  display: flex;
  justify-content: end;
  text-align: right;
  img {
    width: 40px !important;
    height: 40px;
    border-radius: 50%;
    margin-right: 1rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  @media (min-width: ${media.newsTextWidth}px) {
    width: 950px;
  }

  .image-midsection {
    @media (min-width: ${media.newsTextWidth}px) {
      display: flex;
      div {
        width: 100%;
      }
      img {
        width: 80%;
      }
    }
    @supports not (-moz-appearance: none) {
      @media (min-width: ${media.newsTextWidth}px) {
        img {
          width: 45%;
        }
      }
    }
  }

  &:before {
    content: ' ';
    position: absolute;
    font-size: 3rem;
    top: 0;
    left: -2.75rem;
    color: ${colors.blue};
    display: none;

    @media (min-width: ${media.phonePortrait}px) {
      display: inherit;
    }
  }

  p {
    margin-bottom: 2rem;
    line-height: 1.8;
  }
`;

const NewsWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column-reverse;

  img {
    width: 100%;
    max-width: 950px;

    @media (min-width: ${media.navMaxLength}px) {
      width: 950px;
    }

    margin-bottom: 2rem;
    align-self: center;
  }

  ${TextContainer} {
    font-size: 1.2rem;
    line-height: 1.8;
    flex: 1 1 100%;
    padding-bottom: 2rem;
    align-self: center;
  }

  .gatsby-image-wrapper {
    width: 50%;
    margin: 2rem auto;
  }

  @media (min-width: ${media.tabletPortrait}px) {
    align-items: center;
    flex-direction: column-reverse;

    ${TextContainer} {
      font-size: 1.2rem;
      line-height: 1.8;
      flex: 1 0 55%;
      padding-bottom: 0;
    }

    .gatsby-image-wrapper {
      flex: 0 1 45%;
    }

    &:nth-child(2) {
      flex-direction: row-reverse;
    }
    &:nth-child(4) {
      flex-direction: row-reverse;
    }
  }

  &:not(:last-child) {
    padding-bottom: 1.5rem;

    @media (min-width: ${media.tabletPortrait}px) {
      padding-bottom: 2.5rem;
    }
  }

  :last-child {
    .gatsby-image-wrapper picture {
      max-width: 90%;
      max-height: 90%;
    }
  }

  :first-child ${TextContainer}:before {
    left: -2rem;
    color: ${colors.blue};
  }
`;

function NewsPageComponent({
  title = 'How We Accidentally Became Tugger Train Efficiency Experts - Origin Story',
  backgroundColor = colors.lightGrey
}) {
  return (
    <BackgroundContainer backgroundColor={backgroundColor}>
      <div>
        <h2>{title}</h2>

        <HowItWorksContainer>
          <NewsWrapper>
            <TextContainer>
              <p>
                During the vibrant Web Summit in 2019, we were showcasing
                Azitek&#39;s <b>asset-tracking technology</b> when an{' '}
                <strong>Innovation Director</strong> from a{' '}
                <strong>leading automotive supplier</strong> approached us with
                a simple question that would pivot our journey. He asked if we
                could track <strong>tugger trains</strong> in their facility.
                Honestly, the term &#39;tugger train&#39; was new to us, let
                alone grasping the criticality of the milk-run concept in
                manufacturing intralogistics. But, in true{' '}
                <strong>startup spirit</strong>, we accepted the challenge,
                confident in our versatile technology developed from the ground
                up – from the <strong>hardware to the cloud</strong>.
              </p>
              <p>
                We aimed for a proof of concept that was{' '}
                <strong>simple and swift to implement</strong>, cutting through
                the red tape of authorizations and bureaucracies. Our strategy?
                To locate tugger trains at various checkpoints across the shop
                floor using <strong>battery-powered beacons</strong> as
                reference points. Remember, this was for an innovation
                department. It would be many steps before this customer would
                want to buy production-grade solutions. This quick approach
                ensured an effortless setup, free from the constraints of prior
                installation requirements. To integrate seamlessly into the
                vehicles, we customized the{' '}
                <strong>
                  Gateways to connect to the vehicle&#39;s battery
                </strong>{' '}
                with a tailor-made cable.
              </p>
              <p>
                Fast forward a few months, and we found ourselves tracking a
                tugger train across the facility, covering up both{' '}
                <strong>warehouse and production areas</strong>, with 20
                reference points. Our solution was fully offline at this time,
                simply recording position logs and storing them in internal
                memory. However, the real test began when we faced the{' '}
                <strong>harsh realities of intralogistics</strong> &ndash; power
                connection instabilities and a difficult environment that tested
                the durability of all our hardware. But, after a month of trial
                and some setbacks, we successfully extracted the tracking data
                for analysis.
              </p>
              <p>
                During this deep dive into the data, the real challenge revealed
                itself: Providing{' '}
                <strong>meaningful insights and metrics</strong> for the tugger
                train routes was a lot more difficult than we initially thought.
                Given the dynamic and, sometimes, erratic paths these vehicles
                took. <strong>Route discrepancies</strong> proved to be one of
                the first key metrics. Once we solved that, we moved on to other
                KPIs.
              </p>
              <div className="image-midsection">
                <div>
                  <p>
                    As is so common with innovation projects, despite delivering
                    a detailed report from our one-month proof of concept, our
                    first potential client chose <strong>not to proceed</strong>
                    . However, the problem, now clearly understood, was still
                    present. This realization inspired us to investigate further
                    and validate if other tugger train users were facing the
                    same lack of data. Once confirmed, we decided to double
                    down, evolving our solution into an
                    <strong>
                      {' '}
                      online platform that monitors tugger trains 24/7
                    </strong>
                    , offering{' '}
                    <strong>real-time metrics and custom analytics</strong>{' '}
                    tailored to each vehicle, route, and work shift.
                  </p>
                </div>
                <img src={tuggerTrainSmallImg} />
              </div>
              <p>
                Two years later and after several implementations, our
                persistence paid off. Armed with a{' '}
                <strong>robust and comprehensive solution</strong>, we revisited
                our initial prospect and{' '}
                <strong>successfully closed the deal</strong>. This original
                multinational, with nearly a hundred facilities, not only
                embraced our technology for all of their tugger trains but also
                became the{' '}
                <strong>
                  catalyst for our expansion into more of their locations
                </strong>
                .
              </p>
              <p>
                Right now, we&#39;re proud to offer what we believe is the only{' '}
                <strong>comprehensive package</strong> for monitoring tugger
                trains in the market. Going forward, we&#39;re committed to
                continue solving this challenge and enhancing our unique
                solution.
              </p>
              <Signature>
                <img src={valente} />
                <p>
                  <strong>
                    José Valente, CEO & Co-Founder
                    <br /> Jan 2024
                  </strong>
                </p>
              </Signature>
            </TextContainer>
            <img
              className="origin-story-img"
              src={originStoryImg}
              imgStyle={{ height: 'auto' }}
            />
          </NewsWrapper>
        </HowItWorksContainer>
      </div>
    </BackgroundContainer>
  );
}

NewsPageComponent.propTypes = {
  title: PropTypes.string,
  backgroundColor: PropTypes.string
};

export default NewsPageComponent;
