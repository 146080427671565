import React from 'react';
import styled from 'styled-components';
import layoutStyle from '../styles/layout';
import { colors, media } from '../styles/constants';

import Layout from '../components/layout';
import SEO from '../components/seo';
import NewsPageComponent from '../components/newsPageComponent';
import PropTypes from 'prop-types';
import ContactForm from '../components/contactForm';
import originStoryImg from '../assets/images/azitek-origin-story.jpg';

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  ${layoutStyle}

  @media (min-width: ${media.tabletPortrait}px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  > div:first-child {
    flex: 1 0 100%;
    width: 100%;

    @media (min-width: ${media.tabletPortrait}px) {
      margin-right: 4rem;
      flex: 1 0 65%;
    }
  }

  hr {
    border: solid 1px ${colors.blue};
    border-radius: 5px;
    opacity: 0.5;
    max-width: 40rem;
    width: 100%;
    margin: 2rem 0;

    @media (min-width: ${media.tabletPortrait}px) {
      display: none;
    }
  }

  > div:last-child {
    flex: 1 0 50%;

    @media (min-width: ${media.tabletPortrait}px) {
      padding-bottom: 0;
      display: flex;
      flex-direction: column;
      flex: 0 1 45%;
    }

    h3 {
      line-height: 1.2;
      text-align: center;
      color: ${colors.blue};
      font-size: 1.5rem;
    }

    span {
      font-size: 1.25rem;
    }
  }
`;

function NewsPage({ location: { pathname } }) {
  return (
    <>
      <SEO
        title="Origin Story"
        pathname={pathname}
        image={originStoryImg}
        description="How We Accidentally Became Tugger Train Efficiency Experts"
      />
      <Layout>
        <NewsPageComponent />
        <FormContainer>
          <ContactForm title="Contact Us" page="Use Cases" />
          <hr />
        </FormContainer>
      </Layout>
    </>
  );
}

NewsPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default NewsPage;
